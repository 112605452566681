<template>
  <div class="menu">
    <LoginOverlay ref="loginOverlay" :login-options="loginOptions" />
    <div class="menu__create-ad-private">
      <a
        :href="createAd.url"
        :class="createAd.class"
        @click="createAdPrivateClicked()"
        class="button"
      >
        {{ createAd.text }}
      </a>
    </div>
    <div class="menu__desktop">
      <a
        v-for="(item, i) in items"
        :key="'menu-desktop-' + i"
        :href="item.url"
        :class="item.class"
      >
        {{ item.text }}
      </a>
      <a :href="favorites.url" :class="favorites.class">
        {{ favorites.text }}
        <FavoritesCount
          :is-registered="isRegistered"
          :favorites="favorites"
        ></FavoritesCount>
      </a>
      <a
        :href="logout.url"
        :class="logout.class"
        @click="logoutClicked($event)"
        v-if="registered"
      >
        {{ logout.text }}
      </a>
    </div>
    <a :href="dashboard.url" :class="dashboard.class" v-if="registered">
      {{ dashboard.text }}
    </a>
    <a
      :href="login.url"
      :class="login.class"
      @click.prevent="loginClicked($event)"
      v-if="!registered"
    >
      {{ login.text }}
    </a>
    <div class="menu__mobile">
      <OverlayMenu>
        <template v-slot:content>
          <a
            :href="createAd.url"
            :class="createAd.class + '-menu-item menu__item'"
            @click="createAdPrivateClicked()"
          >
            {{ createAd.text }}
          </a>
          <a
            v-for="(item, i) in items"
            :key="'menu-mobile-' + i"
            :href="item.url"
            :class="item.class + '-menu-item menu__item'"
          >
            {{ item.text }}
          </a>
          <a
            :href="favorites.url"
            :class="favorites.class + '-menu-item menu__item'"
          >
            {{ favorites.text }}
            <FavoritesCount
              :is-registered="isRegistered"
              :favorites="favorites"
              :mobile="true"
            ></FavoritesCount>
          </a>
          <a
            :href="logout.url"
            :class="logout.class + '-menu-item menu__item'"
            @click="logoutClicked($event)"
            v-if="registered"
          >
            {{ logout.text }}
          </a>
        </template>
      </OverlayMenu>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'

import { LogPrivateAdFrontpageClick } from '@/events'

import LoginOverlay from '@components/User/Login/LoginOverlay.vue'
import OverlayMenu from '@components/Menu/OverlayMenu.vue'
import FavoritesCount from '@components/FavoritesCount/FavoritesCount.vue'

export default {
  components: {
    LoginOverlay,
    OverlayMenu,
    FavoritesCount,
  },
  data() {
    return {
      inProgress: false,
      registered: false,
    }
  },
  props: {
    items: { default: null, type: Array },
    favorites: { default: null, type: Object },
    createAd: { default: null, type: Object },
    login: { default: null, type: Object },
    logout: { default: null, type: Object },
    dashboard: { default: null, type: Object },
    isRegistered: { default: null, type: Boolean },
    loginOptions: { default: null, type: Object},
  },
  methods: {
    createAdPrivateClicked() {
      LogPrivateAdFrontpageClick()
    },
    logoutClicked(event) {
      event.preventDefault()
      const owner = this

      app.axios.post('/Api/User/Logout').then(() => {
        owner.inProgress = false

        app.toast.success('Du er nu logget ud')
        setTimeout(() => {
          location.href = '/'
        }, 2000)
      })
    },
    loginClicked(event) {
      event.stopPropagation() // Dette er grundet race conditions med window eventet, der lukker dialoger.

      this.$refs.loginOverlay.openForm()
    },
    handleLogin() {
      this.registered = true
    },
  },
  created() {
    app.mitt.on('login-complete', this.handleLogin)
    this.registered = this.isRegistered
  },
}
</script>

<style lang="scss">
@import 'Menu.scss';
</style>
