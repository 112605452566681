import axios from 'axios'

import { logError, handleError } from './errorHandler'

const axiosInstance = axios.create({
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
})

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    const errorCollection = response.data.errorCollection
    if (errorCollection && errorCollection.length === 1) {
      const errorCollectionItem = errorCollection[0]

      if (errorCollectionItem.errorItem === undefined) {
        handleError(errorCollectionItem.errorMessage)
        throw new axios.Cancel('Operation cancelled.')
      }
    }

    return response
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Handling external errors 4xx & 5xx - resulting in basic error message.
  error => {
    const message = [
      'Message: ' + error.toString(),
      'URL: ' + window.location.href,
      'Error object: ' + JSON.stringify(error),
    ].join(' - ')

    logError(message)
    handleError()
  }
)

export { axiosInstance as axios }
