<template>
  <div
    v-if="favoriteCount > 0"
    class="menu__favorites-counter-container"
    :class="{
      'menu__favorites-counter-container--bounce': favoriteBounce,
      'menu__favorites-counter-container--mobile': mobile,
      'menu__favorites-counter-container--desktop': !mobile,
    }"
  >
    <div
      class="menu__favorites-counter-background"
      :class="{
        'menu__favorites-counter-background--bounce': favoriteBounce,
      }"
    ></div>
    <span class="menu__favorites-counter-text">{{ favoriteCountString }}</span>
  </div>
</template>

<script>
import { app } from '@/main'

export default {
  data() {
    return {
      favoriteCount: 0,
      favoriteCountString: '0',
      favoriteBounce: false,
      registered: false,
    }
  },
  props: {
    favorites: { default: null, type: Object },
    isRegistered: { default: null, type: Boolean },
    mobile: { default: false, type: Boolean },
  },
  methods: {
    addToFavoriteCount() {
      this.favoriteCount = this.favoriteCount + 1
    },
    removeFromFavoritCount() {
      this.favoriteCount = this.favoriteCount - 1
    },
    handleLogin(favorites) {
      this.favoriteCount = favorites.length
    },
  },
  watch: {
    favoriteCount() {
      if (this.favoriteCount === undefined) {
        this.favoriteCountString = '0'
      } else if (this.favoriteCount > 99) {
        this.favoriteCountString = '99+'
      } else {
        this.favoriteCountString = this.favoriteCount.toString()
      }
    },
    favoriteCountString() {
      const owner = this
      owner.favoriteBounce = true
      setTimeout(() => {
        owner.favoriteBounce = false
      }, 300)
    },
  },
  created() {
    app.mitt.on('favorite-added', this.addToFavoriteCount)

    app.mitt.on('favorite-removed', this.removeFromFavoritCount)

    app.mitt.on('login-complete', this.handleLogin)
    if (this.favorites !== undefined && this.favorites.count !== undefined) {
      this.favoriteCount = this.favorites.count
    }
  },
}
</script>
