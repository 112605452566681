/* eslint-disable */

import { addClass, removeClass } from '@/functions'

export default {}

export function initListener() {
  let prevScrollpos = window.pageYOffset
  const header = document.querySelector('.header')
  let leftMenu = null

  // delay init to take into account vue loading
  window.setTimeout(() => {
    leftMenu = document.querySelector('.pro-search-wrap')
  }, 2000)

  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset
    if (prevScrollpos == currentScrollPos) return

    if (header !== null) {

      if (prevScrollpos > currentScrollPos) {
        removeClass(header, 'header--hidden')
        if (currentScrollPos != 0) {
          removeClass(header, 'header--top-placement')
        } else {
          addClass(header, 'header--top-placement')
        }
      } else if (currentScrollPos > 48) {
        // fixed currentScrollPos threshold to prettify UI
        addClass(header, 'header--hidden')
      }
    }

    if (leftMenu !== null) {

      if (prevScrollpos > currentScrollPos) {
        removeClass(leftMenu, 'header--hidden')
        if (currentScrollPos != 0) {
          removeClass(leftMenu, 'header--top-placement')
        } else {
          addClass(leftMenu, 'header--top-placement')
        }
      } else if (currentScrollPos > 48) {
        // fixed currentScrollPos threshold to prettify UI
        addClass(leftMenu, 'header--hidden')
      }
    }

    prevScrollpos = currentScrollPos
  }
}
